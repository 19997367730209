import React, {useState} from "react"
import "./LeftSideBar.scss"
import {NavLink} from "react-router-dom"
import cn from 'classnames'

const LeftSideBar = () => {
    const [openState, setOpenState] = useState(false)
    const accessLevel = localStorage.getItem('accessLevel')

    const openCloseMenu = () => {
        const wrap = document.querySelector('.main-wrap')
        const mainContainer = document.querySelector('.main-container')

        setOpenState(!openState)

        if (!openState) {
           mainContainer.classList.add('main-container_open-menu')
           wrap.classList.add('main-wrap_open-menu')
        } else {
            wrap.classList.remove('main-wrap_open-menu')
            setTimeout(() => {
                mainContainer.classList.remove('main-container_open-menu')
            }, 210);
        }
    }

    const closeMenu = () => {
        const wrap = document.querySelector('.main-wrap')
        const mainContainer = document.querySelector('.main-container')

        setOpenState(false)

        wrap.classList.remove('main-wrap_open-menu')

        setTimeout(() => {
            mainContainer.classList.remove('main-container_open-menu')
        }, 210)
    }

    const sideBarClasses = cn({
        'left-side-bar': true,
        'left-side-bar__active': openState
    })

    return (
        <div className={sideBarClasses}>
            <div onClick={() => openCloseMenu()} className="left-side-bar__burger"/>

            <div className="left-side-bar__logo">
                <NavLink to="/desktop">Logo</NavLink>
            </div>

            <menu className="left-side-bar__menu">
                <li>
                    <NavLink onClick={closeMenu} to="/desktop" className="left-side-bar__desktop"><span>Рабочий стол</span></NavLink>
                </li>

                <li>
                    <NavLink onClick={closeMenu} to="/replica" className="left-side-bar__replics"><span>Реплики</span></NavLink>
                </li>

                <li>
                    <NavLink onClick={closeMenu} to="/analise" className="left-side-bar__analise"><span>Анализ</span></NavLink>
                </li>

                {/*<li>*/}
                {/*    <NavLink onClick={closeMenu} to="/search" className="left-side-bar__search"><span>Поиск</span></NavLink>*/}
                {/*</li>*/}

                {/*<li>*/}
                {/*    <NavLink onClick={closeMenu} to="/monitoring" className="left-side-bar__info"><span>Мониторинг</span></NavLink>*/}
                {/*</li>*/}

                {accessLevel === '1' || accessLevel === '5' ? (
                    <li className="active">
                        <NavLink onClick={closeMenu} to="/admin" className="left-side-bar__settings"><span>Администратор</span></NavLink>
                    </li>
                ) : null}
            </menu>
        </div>
    )
}

export default LeftSideBar
