import React, { useContext, useEffect, useState } from 'react'
import Layout from './hoc/Layout/Layout'
import Authorization from './containers/Authorization/Authorization'
import { Switch, Route, Redirect } from 'react-router-dom'
import AdminCompany from './containers/AdminCompany/AdminCompany'
import AdminClient from './containers/AdminClient/AdminClient'
import AdminManager from './containers/AdminManager/AdminManager'
import Desktop from './containers/Desktop/Desktop'
import Replica from './containers/Replica/Replica'
import Logout from './containers/Logout/Logout'
import Analise from './containers/Analise/Analise'
import Search from './containers/Search/Search'
import { AuthContext } from './context/auth/authContext'
import { apiUserCheckToken } from './api/users'
import Monitoring from './containers/Monitoring/Monitoring'
import { PopupContext } from './context/popup/popupContext'
import PopupNotice from './components/PopupNotice/PopupNotice'
import { CSSTransition } from 'react-transition-group'
import Preloader from './components/Preloader/Preloader'

import './style/normalize.css'
import './style/fonts.css'
import './style/main.css'
import './style/replica.scss'
import './style/admin-company.scss'
import './style/selectWithCounter.scss'

const checkAuth = () => {
    const token = localStorage.getItem('token')
    const tokenExp = new Date(localStorage.getItem('tokenExp'))

    if (token && tokenExp && tokenExp > new Date()) {
        return true
    } else {
        return false
    }
}

const logout = () => {
    localStorage.clear()
}

const App = () => {
    const popupContextData = useContext(PopupContext)
    const authContextData = useContext(AuthContext)
    const authResult = checkAuth()
    const [pageView, setPageView] = useState(false)
    const accessLevel = localStorage.getItem('accessLevel')

    if (authResult) {
        authContextData.auth = true
    } else {
        logout()
    }

    useEffect(() => {
        const checkTokenOnBack = async () => {
            const token = localStorage.getItem('token')

            if (token) {
                const result = await apiUserCheckToken(token)

                if (result.status === 'success') {
                    authContextData.setAuth(true)
                    setPageView(true)
                } else {
                    logout()
                    authContextData.setAuth(false)
                    document.location.href = '/'
                }
            } else {
                logout()
                authContextData.setAuth(false)
                setPageView(true)
            }
        }

        checkTokenOnBack()
        // eslint-disable-next-line
    }, [])

    let routes = (
        <Switch>
            <Route path={'/'} component={Authorization} exact />
            <Redirect to={'/'} exact />
        </Switch>
    )

    if (authContextData.auth) {
        if (accessLevel === '1') {
            routes = (
                <Switch>
                    <Route path={'/desktop'} component={Desktop} />
                    <Route path={'/replica'} component={Replica} />
                    <Route path={'/analise'} component={Analise} />
                    {/*<Route path={'/search'} component={Search} />*/}
                    {/*<Route path={'/monitoring'} component={Monitoring} />*/}
                    <Route path={'/admin/manager'} component={AdminManager} />
                    <Route path={'/admin/client'} component={AdminClient} />
                    <Route path={'/admin'} component={AdminCompany} update={'1'} />
                    <Route path={'/logout'} component={Logout} />
                    <Redirect to={'/desktop'} exact />
                </Switch>
            )
        } else if (accessLevel === '5') {
            routes = (
                <Switch>
                    <Route path={'/desktop'} component={Desktop} />
                    <Route path={'/replica'} component={Replica} />
                    <Route path={'/analise'} component={Analise} />
                    {/*<Route path={'/search'} component={Search} />*/}
                    {/*<Route path={'/monitoring'} component={Monitoring} />*/}
                    <Route path={'/admin/client'} component={AdminClient} />
                    <Route path={'/admin'} component={AdminCompany} update={'1'} />
                    <Route path={'/logout'} component={Logout} />
                    <Redirect to={'/desktop'} exact />
                </Switch>
            )
        } else {
            routes = (
                <Switch>
                    <Route path={'/desktop'} component={Desktop} />
                    <Route path={'/replica'} component={Replica} />
                    <Route path={'/analise'} component={Analise} />
                    {/*<Route path={'/search'} component={Search} />*/}
                    {/*<Route path={'/monitoring'} component={Monitoring} />*/}
                    <Route path={'/logout'} component={Logout} />
                    <Redirect to={'/desktop'} exact />
                </Switch>
            )
        }
    }

    return (
        <Layout>
            {pageView ? routes : null}

            <CSSTransition in={popupContextData.popupNotice.state} timeout={200} mountOnEnter={true} unmountOnExit={true}>
                {(state) => <PopupNotice className={state} />}
            </CSSTransition>

            <CSSTransition in={popupContextData.preloader} timeout={200} mountOnEnter={true} unmountOnExit={true}>
                {(state) => <Preloader className={state} />}
            </CSSTransition>
        </Layout>
    )
}

export default App
