import React, { useContext, useEffect, useState } from 'react'
import classes from './Desktop.module.scss'
import { PopupContext } from '../../context/popup/popupContext'
import { SelectBlock } from '../../components/Desktop/SelectBlock/SelectBlock'
import { DefaultTilesBlock } from '../../components/Desktop/DefaultTilesBlock/DefaultTilesBlock'
import { CustomTilesBlock } from '../../components/Desktop/CustomTilesBlock/CustomTilesBlock'
import { useWindowSize } from '../../hooks/useWindowSize'
import { EditButton } from '../../components/Desktop/EditButton/EditButton'
import axios from 'axios'
import config from '../../config/config'
import DesktopPlanStart from '../../components/Desktop/DesktopPlanStart/DesktopPlanStart'
import DesktopPlan from '../../components/Desktop/DesktopPlan/DesktopPlan'
import { apiUsersInProjects, updateAllowByUsers } from '../../api/users-in-project'
import Accordion from '../../components/UI/Accordion/Accordion'
import Dropdown from '../../components/UI/Dropdown/Dropdown'
import Tooltip from 'react-power-tooltip'
import { apiProjectAddPlan, apiProjectGetPlan } from '../../api/projects'
const Desktop = () => {
    const popupContextData = useContext(PopupContext)

    const [plan, setPlan] = useState({
        planNull: null,
        planOne: null,
        planTwo: null
    })



    const accessLevel = localStorage.getItem('accessLevel')

    const token = localStorage.getItem('token')

    const [isCreatedFolder, setIsCreatedFolder] = useState(false)

    const [desktopData, setDesktopData] = useState({})
    const [size, setSize] = useState(0)

    const [selectData, setSelectData] = useState({})

    const getDesktopData = async (first, company, project) => {
        popupContextData.setPreloader(true)
        if (first) {
            const data = await axios.get(`${config.SERVER_BASE_URL}/desktop/?autoSelect=Y&token=${token}`)
            setDesktopData(data.data)
            setSize(data.data.size)
        } else {
            if (company) {
                const data = await axios.get(`${config.SERVER_BASE_URL}/desktop/?company=${company}&project=${project || ''}&autoSelect=${!project && 'Y'}&token=${token}`)
                setDesktopData(data.data)
                setSize(data.data.size)
            } else {
                alert('Не передана компания')
            }
        }

        popupContextData.setPreloader(false)
    }

    const onChangeSelect = async (select, el) => {
        popupContextData.setPreloader(true)
        const id = el.target.value

        if (select === 'company') {
            await getDesktopData(false, id)
        } else {
            await getDesktopData(false, selectData.company, id)
        }
        popupContextData.setPreloader(false)
    }

    const onChangeTextInput = async (blockName, blockType, documents, description, name) => {
        popupContextData.setPreloader(true)
        for (const block of desktopData[blockName]) {
            if (block._id === blockType) {
                let req = {}
                req = { ...block, description, name, documents }

                req.token = token
                console.log(`[DEV] @@@onChangeTextInput documents: ${JSON.stringify(documents)}, num: ${documents.length}`)

                if (req.documents.length > 0) {
                    const formData = new FormData()
                    let name = ''

                    req.documents.forEach((el, i) => {
                        if (el.file.id) {
                            if (block.name && el.name)
                                name += `${block.name}/${el.name},`
                            else if (block.name && !el.name) {
                                name += `${block.name},`
                            } else if (!block.name) {
                                name += `${el.name},`
                            }

                            req.documents[i].file.path = name.replaceAll(',', '') + '/' + el.file.name
                            formData.append('file', el.file.file)
                        }
                    })


                    if (formData.getAll('file').length > 0)
                        await axios.post(
                            `${config.SERVER_BASE_URL}/desktop/file/?project=${selectData.project}&company=${selectData.company}&dirName=${name}&token=${token}`,
                            formData,
                            {
                                headers: { 'Content-Type': 'multipart/form-data' },
                                data: formData
                            }
                        )
                }

                await axios.put(
                    `${config.SERVER_BASE_URL}/desktop/block/${block._id}/?project=${selectData.project}&company=${selectData.company}`,
                    req
                )

                await getDesktopData(false, selectData.company, selectData.project)

            }
        }
        popupContextData.setPreloader(false)
    }


    const onCreateDir = async (data) => {

        popupContextData.setPreloader(true)

        console.log(`[DEV] onCreateDir data: ${JSON.stringify(data)}`)


        const req = {
            name: data.name,
            description: !!data.description ? data.description : null,
            documents: data.documents,
            token: token,
            project: selectData.project,
            company: selectData.company,
            desktopId: desktopData.desktopId
        }

        if (data.documents.length > 0) {
            const formData = new FormData()

            let name = `${data.name}/${data.documents[0].file.name},`

            req.documents[0].file.path = name.replaceAll(',', '')

            formData.append('file', data.documents[0].file.file)

            await axios.post(
                `${config.SERVER_BASE_URL}/desktop/file/?project=${selectData.project}&company=${selectData.company}&dirName=${data.name}&token=${token}`,
                formData,
                {
                    headers: { 'Content-Type': 'multipart/form-data' },
                    data: formData
                }
            )
        }

        await axios.post(
            `${config.SERVER_BASE_URL}/desktop/block`,
            req
        )

        await getDesktopData(false, selectData.company, selectData.project)

        popupContextData.setPreloader(false)
        setIsCreatedFolder(true)
    }

    const deleteTile = async (id) => {
        popupContextData.setPreloader(true)
        await axios.delete(
            `${config.SERVER_BASE_URL}/desktop/block/${id}/?token=${token}`
        )
        await getDesktopData(false, selectData.company, selectData.project)
        popupContextData.setPreloader(false)
    }

    const fillSelectData = () => {
        if (Object.keys(desktopData).length) {
            const companiesCpy = desktopData.companies
            const projectsCpy = desktopData.projects

            const companies = companiesCpy.map((el) => {
                return {
                    name: el.name,
                    id: el._id
                }
            })
            const projects = projectsCpy.map((el) => {
                return {
                    name: el.name,
                    id: el._id
                }
            })
            setSelectData({
                companies: companies,
                projects: projects,
                project: desktopData.projectActive,
                company: desktopData.companyActive
            })
        }
    }

    useEffect(() => {
        getDesktopData(true)
    }, [])

    useEffect(() => {
        fillSelectData()
    }, [desktopData])


    const onTapUploadFiles = (id) => {

    }


    const [users, setUsers] = useState([])


    const getPlan = async () => {
        if (selectData.project) {

            try {
                const { data } = await apiProjectGetPlan(selectData.project)

                if (data?.status === 'success' && data?.plan?.length) {
                    let copyPlan = { ...plan }

                    data.plan.forEach((item) => {
                        if (item.type === null) {
                            copyPlan = { ...copyPlan, planNull: item.data }
                        }

                        if (item.type === 1) {
                            copyPlan = { ...copyPlan, planOne: item.data }
                        }

                        if (item.type === 2) {
                            copyPlan = { ...copyPlan, planTwo: item.data }
                        }
                    })

                    setPlan(copyPlan)
                }

            } catch (error) {

            }
        }
    }

    useEffect(() => {
        fetchUsersByProject()
        getPlan()
    }, [selectData.project])



    const fetchUsersByProject = async () => {
        try {
            if (selectData.project) {
                const link = await apiUsersInProjects(selectData.project)
                setUsers(link)
                console.log(`[fetchUsersByProject] link: ${JSON.stringify(link)}`)
            }


        } catch (error) {

        }

    }


    const onChangeSingle = async (value, userId) => {
        try {
            console.log(`onChangeSingle. value: ${JSON.stringify(value)}`)

            const newArray = [...users].map((currentUser) => {
                if (userId === currentUser.userId._id) {
                    return { ...currentUser, allow: value }
                }

                return currentUser
            })

            console.log(`onChangeSingle. newArray: ${JSON.stringify(newArray)}`)

            setUsers(newArray)

            const data = await updateAllowByUsers({ projectId: selectData.project, allow: value, users: userId })
            console.log(`onChangeSingle. data: ${JSON.stringify(data)}`)

        } catch (error) {
            console.error(`onChangeSingle. error: ${error?.message ?? error.toString()}`)

        }

    }

    const onChangeMultiple = async (value, roleId) => {
        try {
            const newArray = [...users].map((currentUser) => {
                if (roleId === currentUser.userId.role.accessLevel) {
                    return { ...currentUser, allow: value }
                }

                return currentUser
            })

            setUsers(newArray)

            const updates = newArray.filter((user) => {
                return user.userId.role.accessLevel === roleId
            }).map((item) => item.userId._id).join()
            const data = await updateAllowByUsers({ projectId: selectData.project, allow: value, users: updates })
            console.log(`onChangeSingle. data: ${JSON.stringify(data)}`)

        } catch (error) {
            console.error(`onChangeMultiple. error: ${error?.message ?? error.toString()}`)
        }
    }



    //FIXME метод дублируется
    const onSavePlan = async (arr) => {
        let preResult = []

        preResult = arr.map((mainItem) => {
            let data = []

            mainItem.data.forEach((item) => {
                let newItems = []

                item.items.forEach((itemInner) => {
                    if (itemInner?.items?.length) {
                        itemInner.items.forEach((itemInnerLvlTwo) => {
                            const newItemInnerLvlTwo = Object.entries(itemInnerLvlTwo).reduce((obj, [key, value]) => {
                                if (key !== 'id' && key !== 'items') {
                                    obj[key] = value.value
                                } else {
                                    obj[key] = value
                                }

                                return obj
                            }, {})

                            newItems.push({
                                ...newItemInnerLvlTwo,
                                name: newItemInnerLvlTwo?.newUrl || newItemInnerLvlTwo?.url || newItemInnerLvlTwo.name
                            })
                        })
                    } else {
                        const newItemInner = Object.entries(itemInner).reduce((obj, [key, value]) => {
                            if (key !== 'id' && key !== 'items') {
                                obj[key] = value?.value !== 'undefined' ? value.value : value
                            } else {
                                obj[key] = value
                            }

                            return obj
                        }, {})

                        return newItems.push({
                            ...newItemInner,
                            name: newItemInner?.newUrl || newItemInner?.url || newItemInner.name
                        })
                    }
                })

                const newItem = {
                    ...item,
                    dateStart: item.dateStart ? item.dateStart.toLocaleDateString('ru-RU') : '',
                    dateEnd: item.dateEnd ? item.dateEnd.toLocaleDateString('ru-RU') : '',
                    items: newItems
                }

                return data.push(newItem)
            })

            return {
                ...mainItem,
                data: data
            }
        })

        popupContextData.setPreloader(true)
        const data = await apiProjectAddPlan(selectData.project, JSON.stringify(preResult))

        if (data && data.status === 'fail') {
            popupContextData.setPreloader(false)

            if (data?.errorText) {
                alert(data.errorText)
            }
        } else {
            // props.getProjects()
            // props.close()
            popupContextData.setPreloader(false)
            getPlan()
        }
    }


    return (
        <div className={`replic ${classes.Desktop}`}>
            {isCreatedFolder ? null : <EditButton onClick={() => setIsCreatedFolder(true)} />}

            <div className={classes.DesktopWrapper}>
                <h1 className={classes.Title}>
                    Рабочий стол
                </h1>

                <SelectBlock
                    items={selectData}
                    onChange={onChangeSelect}
                />

                <DefaultTilesBlock
                    tiles={desktopData.blocksSystem || []}
                    onTapUploadFiles={onTapUploadFiles}
                    onChange={onChangeTextInput}
                    size={size}
                    setSize={setSize}
                    isCreatedFolder={isCreatedFolder}
                    onCancelCreatedFolder={() => setIsCreatedFolder(false)}
                    onSuccessCreatedFolder={onCreateDir}

                />

                <CustomTilesBlock
                    tiles={desktopData.blocksCustom || []}
                    onChange={onChangeTextInput}
                    onTapUploadFiles={onTapUploadFiles}
                    deleteTile={deleteTile}
                    size={size}
                    setSize={setSize}
                    isCreatedFolder={isCreatedFolder}
                    onCancelCreatedFolder={() => setIsCreatedFolder(false)}
                    onSuccessCreatedFolder={onCreateDir}
                />

                {/*{accessLevel === '1' && (*/}
                {/*    <>*/}
                {/*        <Dropdown isLoading={popupContextData.preloader} data={users} onChangeSingle={onChangeSingle} onChangeMultiple={onChangeMultiple} />*/}
                {/*        <DesktopPlan title='План' plan={plan} isControl={true} onSavePlan={onSavePlan}  />*/}
                {/*    </>)}*/}
            </div>
        </div>
    )
}

export default Desktop
